.image-gallery {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    font-family: 'aquinna' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2em !important;
  }

  .image-gallery-left-nav:before {
    content: '\e905';
  }
  .image-gallery-right-nav:before {
    content: '\e906';
  }

  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    white-space: initial !important;
  }
}
