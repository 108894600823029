section.image {
  position: relative;

  .img-container {
    overflow: hidden;
    width: 100%;
    position: relative;
    background-size: cover;
    height: 300px;

    @media (min-width: $screen-xs-min) {
      height: 150px;
    }
    @media (min-width: $screen-sm-min) {
      height: 200px;
    }
    @media (min-width: $screen-md-min) {
      height: 250px;
    }
    @media (min-width: $screen-lg-min) {
      height: 300px;
    }

    .img-responsive {
      min-width: 100%;
    }
  }

  .title {
    position: absolute;
    bottom: 0;
    background-color: rgba(44, 31, 31, 0.75);
    width: 100%;
    height: 50px;
    color: white;

    h1 {
      margin-top: 5px;
    }
  }
}

section.space {
  margin-top: 20px;
}

.about-info {
  color: white;
  background-size: cover;
  width: 100%;

  .info {
    background: rgba($brand-primary, 0.9);
    max-width: 450px;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    min-height: 350px;
  }

  &.trans {
    background-color: $gray-dark;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.about-people {
  padding: 30px 0;
  background-color: $gray-dark;
}

.team-image {
  width: 300px;
  height: 400px;
}

.welcome {
  padding-left: 10px;
  padding-right: 10px;
}

//ContactPage
.table-borderless td,
.table-borderless th {
  border-top: none !important;
}

.table-no-padding td,
.table-no-padding th {
  padding: 0 !important;
}

.gallery {
  padding-bottom: 20px;
  .thumbnail {
    margin: 0 auto;
    width: 350px;
    //background-color: lighten($brand-primary, 10%);
    border: none;
    cursor: pointer;

    img {
      margin: 0 auto;
      max-height: 186px;
    }
  }
}

.consumer-code {
  a {
    color: $gray-lighter;
  }
}
