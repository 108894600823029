//
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @include container-fixed;
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row;
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-grid(xs);

//Custom
@media (min-width: $screen-ms-min) {
  @include make-grid(ms);
}

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}



/*EXTRA*/
@mixin row-first-child($col-type) {
  .col-#{$col-type}- {
    &1:nth-child(12n+1),
    &2:nth-child(6n+1),
    &3:nth-child(4n+1),
    &4:nth-child(3n+1),
    &6:nth-child(odd){
      clear: left;
    }
  }
}

.auto-clear {
  @media (min-width: $screen-lg-min){
    @include row-first-child(lg);
  }
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max){
    @include row-first-child(md);
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    @include row-first-child(sm);
  }
  @media (max-width: $screen-xs-max){
    @include row-first-child(xs);
  }
}
