.search {
  background-color: #f5f5f5;

  form {
    margin-top: 20px;
  }

  .search-submit {
    margin-top: 10px;
  }
}

.home {
  #search_container {
    @media (max-width: $screen-sm-max) {
      background: rgba($brand-primary, 0.75);
      padding: 1px 7px;
    }
  }
}

section.search {
  #search_container label {
    margin-top: 18px;
    @media (max-width: $screen-sm-max) {
      margin-top: 0;
    }
  }
}

#search_container {
  color: $gray-darker;

  label {
    margin-top: 10px;
  }
  .search-box {
    margin-top: 10px;
    position: relative;
  }
  .search-submit {
    margin-top: 10px;
  }
}

.map-list {
  margin: 12px 0;

  button {
    color: #a1a1a1;
  }
}

.development-map {
  height: 600px;
}

.developments-container {
  background-color: $gray-dark;
  color: $gray-lighter;
  height: 100%;

  a {
    color: lighten($brand-primary, 5%);

    &:hover {
      color: lighten($brand-primary, 15%);
    }
  }

  .icon-chevron-right {
    vertical-align: middle;
  }
}

.development-container {
  padding-bottom: 50px;
}

.development-item {
  max-width: 380px;
  margin: 15px 0 !important;
  color: $gray-darker;
  position: relative;

  a {
    color: $brand-primary;
  }
  .shadow {
    background-color: white;
    box-shadow: -1px 2px 6px darken($brand-primary, 10%);
    position: relative;
    width: 100%;
  }

  .img-responsive {
    height: 200px;
    background-color: $gray-light;
  }

  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid $brand-red;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 38px;
    background-color: $brand-red;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
  }

  /* top left*/
  .ribbon-top-left {
    top: 0;
    left: 0;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }

  .dev-button {
    background-color: #eeeeee;
    height: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
    div {
      text-align: center;
      padding-top: 15px;
      a {
        font-weight: bold;
      }
    }
  }

  .forthcoming-container {
    padding-bottom: 30px;

    .available-from {
      position: absolute;
      bottom: 0;
    }
  }
}
