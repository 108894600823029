
.row-flex, .row-flex > div[class*='col-'] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.row-flex-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.row-flex-wrap:after {
  content: "";
  -ms-flex: auto;
      flex: auto;
}
