html {
  height: 100%;
  width: 100%;
}

body,
.app,
#__next {
  display: table;
  width: 100%;
  height: 100%;
}

.container {
  height: auto;

  &.page {
    background-color: white;
  }
}

footer {
  display: table-row;
  width: 1px;
  background-color: $brand-primary;
  text-align: center;
  //padding-top: 100px;
  #footer-main {
    padding: 20px 0;
  }

  .social {
    margin-bottom: 15px;
    text-align: center;
    a {
      margin: 0 10px;
      display: inline-block;
      text-decoration: none;
    }
  }
  a {
    color: white;
    &:hover,
    &:active,
    &:focus {
      color: white;
    }
  }
  color: white;

  .small {
    margin: 0;
  }

  .cookie-footer {
    background-color: #555;
    margin-top: 10px;
    margin-bottom: 2px;

    a {
      font-weight: bold;
    }
  }
}

header {
  display: table-row;
  width: 1px;
}

#content {
  background-color: #f0e9e0;
  display: table-row;
  width: 1px;
  height: 100%;
}

.navigation {
  margin-top: 10px;

  .nav-pills {
    font-size: 16px;
  }
}

.logo {
  .navbar-brand {
    height: 100%;
    padding: 0;

    img {
      height: 67px;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    margin-top: 7px;
    .navbar-brand {
      img {
        height: 60px;
        max-width: 200px;
      }
    }

    .aspire {
      left: 137px !important;
    }
  }

  .aspire {
    position: absolute;
    bottom: -23px;
    left: 150px;
  }
}

#nav-line {
  background: $brand-red;
  height: 20px;
}

#search_container {
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loading {
  background-color: lighten($gray-light, 40%);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    lighten($gray-light, 40%) 8%,
    lighten($gray-light, 30%) 18%,
    lighten($gray-light, 40%) 33%
  );
  background-size: 800px 104px;
  position: relative;
}
