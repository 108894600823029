.navbar-brand {
  img {
    height: 50px;
  }
}

.navbar-default .navbar-nav>li>a {
  color: white;
}

.navigation {
  margin-top: 20px;
}

.nav-row {
  margin-top: 27px;
}

ul li:hover > .submenu {
  display: block;
}

.nav-pills>li>a {
  border-radius: 0;
  color: $gray-darker;
  cursor: pointer;
}

.nav>li>a:hover, .nav>li.active>a, .nav>li.active>a:hover, .nav>li:hover>a {
  background: darken($component-active-bg, 10%);
  color: white;
}

a.thumbnail {
  background-color: transparent;
  border: none;
}

a.thumbnail .caption {
  color: white;
}
