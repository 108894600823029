.news-wrapper {
  @media(min-width: $screen-sm-min) {
    &:nth-child(2n) {
      margin-top: 120px;
    }
  }

  margin-top: 20px;
}

.news-header {

  min-height: 100px;
  position:relative;
  overflow: hidden;
  color: $gray-lighter;
  background-color: $gray-darker;

  a {
    color: $gray-lighter;
    text-decoration: none;
  }

  .news-image {
    background-image: url(/images/development_tb.jpg);
    background-size: cover;
    position: absolute;


    top: 0;
    left: 0;
    bottom: 0;
    right: 0;


    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(black, .6);
    }

  }

  .title {
    font-size: 30px;
    position: relative;
    text-transform: uppercase;
    padding: 40px;
    text-align: center;
    margin: 0;
    font-weight: normal;
    max-height: 272px;
    overflow: hidden;
    color: $gray-lighter;
  }

}

.news-item {
  box-shadow: -6px 10px 40px 6px rgba(black, .15);
  background-color: white;
  border-radius: 10px;
  position: relative;

  .news-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 250px;

    .title {
      max-height: 207px;
    }
  }


  &:hover .news-image {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .news-image {
    transition: all .5s cubic-bezier(.59,0,.38,1);
    &:before {
      background-color: rgba(darken($brand-primary, 10%), .5);
    }
  }

  .content {
    color: $gray;
    height: 200px;
    padding: 20px;


    .date {
      font-size: 15px;
    }

    .text {
        display:block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        height: 7em;
        line-height: 1.4em;
    }

    .read-more {
      letter-spacing: .15em;
      bottom: 5px;
      position: absolute;
      width: 100%;
      margin-left: -20px;

      p {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        transition: all .5s cubic-bezier(.59,0,.38,1);

        &:hover {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        a {
          color: $brand-primary;
          text-decoration: none;
          cursor: pointer;

        }
      }


    }
  }
}

.news {
  background-color: $gray-dark;
  height:100%;

  .article-container {
    font-size: 16px;
    margin-top: 20px;
    padding-bottom: 20px;
    color: $gray-lighter;
  }

}
