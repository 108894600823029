section.development {
    .header {
        color: white;
        position: relative;

        .header-image {
          height: 250px;
          background-size: cover;
          background-repeat: no-repeat;
          @media (min-width: $screen-xs-min) {
            height: 300px;
          }
          @media (min-width: $screen-sm-min) {
            height: 450px;
          }
          @media (min-width: $screen-md-min) {
            height: 550px;
          }
          @media (min-width: $screen-lg-min) {
            height: 630px;
          }
        }

        .content {
            padding: 25px 15px;
            bottom:0;
            background-color: $brand-primary;
            width: 100%;
            //height: 200px;
            color:white;
        }

        .price {
            margin-top: 5px;
            margin-bottom: 0;
        }

        .name {
          margin: 0;
        }

        .location {
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 15px;
        }
        .address {
            margin-top: 20px;
        }
    }

    .map {
        background-color: #584C4C;
        color: white;
        h2 {
            margin-top: 10px;
        }

        .development-map {
            height: 600px;
        }

    }

    .properties {
        margin-top: 30px;
    }

    .specification {
        background-color: $gray-lighter;
        color: $gray-darker;
        margin-top: 30px;
    }

    .help-to-buy {
        background-color: $gray-lighter;
        color: $gray-darker;

        .image {
            margin:20px 0;
            text-align:center;
        }
    }

    .gallery {
        .carousel-inner {
            height: 650px;
        }

    }

    .contact {
        background-color: $gray;
        color:white;

        .fa {
            font-size: 20px;
        }

        dl {
            dt {
                width: 50px;
                margin-top: 10px;
            }
            dd {
                margin-left: 70px;
                margin-top: 10px;
            }
            dt:first-child, dd:first-child {
                margin-top: 0;
            }
        }
    }
}

table.table-icon tr {
  &:first-child > td {
    padding-top:0;
  }

  &> td {
    padding-top: 10px;
    border-top: none;
    vertical-align: middle;
    &:first-child {
      width: 40px;
    }

    a {
      color: white;
    }
  }
}

table.table-icon.compact tr > td {
  padding: 2px;
}

.development-map {
  height: 500px;
}
