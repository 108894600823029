.home {

  .fast-track {
    color: $gray-lighter;
    background-color: $gray;
  }
  #search_container {
    color: white;
  }


}

.container-home {
  margin-top: 5px;
}

.post-content {
  background: rgba(darken($brand-primary, 10%), 0.8);
  bottom:0;
  left:0;
  position: absolute;
  width: 100%;
  padding-top: 7px;
  padding-left: 15px;
  color: white;
}

.home-image {
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
  min-height: 250px;

  &.large {
    min-height: 400px;
    @media (min-width: $screen-xs-min) {
      min-height: 200px;
    }
    @media (min-width: $screen-sm-min) {
      min-height: 360px;
    }
    @media (min-width: $screen-md-min) {
      min-height: 470px;
    }
    @media (min-width: $screen-lg-min) {
      min-height: 570px;
    }
  }

  .overlay {

    i {
      background-color: transparentize($brand-primary, 0.3);
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      opacity: 0;
      -webkit-transform: translatez(0);
      -webkit-transition: opacity 0.5s ease-in-out;
      -o-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out;

      display: -webkit-box !important;
      display: -moz-box !important;
      display: -ms-flexbox !important;
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      justify-content: center;

    }

    &:hover i {
      opacity: 1;
      text-decoration: none;
    }


    .caption {
      opacity: 1;
    }
  }
}

section {
  &.title {
    //background-color: $brand-primary;
    position: relative;
    li a {
      color: white;
    }
    .title-image {
      position: relative;
      width: 100%;
      height: 575;
    }
    .title-base {
      padding: 25px 15px;
      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 150px;
      color: white;

      @media (min-width: $screen-md-min) {
        background-color: rgba($brand-primary, 0.75);
      }
    }

    .title-text {
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      margin: 10px;
      top: 10px;
      position: relative;
    }
  }
}

.search-button {
  margin-top: 5px;
}

@media (min-width: $screen-md-min) {
  .search-button {
    margin-top: 35px;
  }
}

.home-title {
  .titleBase {
    padding: 20px 15px;
    //position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 120px;
    color: white;
  
    @media (min-width: $screen-md-min) {
      background-color: $brand-primary;
    }
  }
  
  .searchContainer {
    // color: $gray-darker;
    color: white;
  
    label {
      margin-top: 10px;
    }
    .search-box {
      margin-top: 10px;
      position: relative;
    }
    .search-submit {
      margin-top: 10px;
    }
  }
  
  .searchButton {
    margin-top: 5px;
    @media (min-width: $screen-md-min) {
      margin-top: 35px;
    }
  }
  
  .searchLabel {
    color: $brand-primary;
    @include tablet {
      color: inherit;
    }
  }
}

.cycler {
  @media (min-width: $screen-xs-min) {
    height: 200px;
  }
  @media (min-width: $screen-sm-min) {
    height: 350px;
  }
  @media (min-width: $screen-md-min) {
    height: 500px;
  }
  @media (min-width: $screen-lg-min) {
    height: 550px;
  }

  height: 200px;
  position: relative;
  overflow: hidden;

  .crossfade {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat center center;
    background-size: cover;
    object-fit: cover;
    // -webkit-transition: opacity 1s ease-in-out;
    // -moz-transition: opacity 1s ease-in-out;
    transition: opacity 3s ease-in-out, transform 10s;
  }
}


.map {
  .mapContainer {
    height: 500px;
    position: relative;
  }

  .mapKey {
    background: $content-bg;
    color: $gray-dark;
    z-index: 20000;
    right: 0;
    position: absolute;
    padding: 10px;
  }

  .keyIcon {
    width: 32px;
  }
}

.introduction {
  .item {
    position: relative;
    margin: 3px 5px;
    img {
      width: 100%;
    }
  }
  
  .homeImage {
    position: relative;
    margin-top: 2px;
    margin-bottom: 2px;
    // min-height: 250px;
  
    &.large {
      min-height: 400px;
      @include mobile {
        min-height: 200px;
      }
      @include tablet-mini {
        min-height: 360px;
      }
      @include tablet {
        min-height: 470px;
      }
      @include desktop {
        min-height: 570px;
      }
    }
  
    .overlay {
      i {
        background-color: transparentize($brand-primary, 0.3);
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
        opacity: 0;
        -webkit-transform: translatez(0);
        -webkit-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;
  
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
      }
  
      p {
        margin: 0;
      }
  
      &:hover i {
        opacity: 1;
        text-decoration: none;
      }
  
      .caption {
        opacity: 1;
        background: rgba(darken($brand-primary, 10%), 0.8);
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 15px;
        color: white;
      }
    }
  }
  
  .icon {
    float: right;
    .chevron {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  
}