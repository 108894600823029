.icon-2x {
  font-size: 2em !important;
}

.icon-3x {
  font-size: 3em !important;
}

.icon-4x {
  font-size: 4em !important;
}

.icon-5x {
  font-size: 5em !important;
}

@font-face {
  font-family: 'aquinna';
  src: url('../fonts/aquinna.eot?o6zwjj');
  src: url('../fonts/aquinna.eot?o6zwjj#iefix') format('embedded-opentype'),
    url('../fonts/aquinna.ttf?o6zwjj') format('truetype'),
    url('../fonts/aquinna.woff?o6zwjj') format('woff'),
    url('../fonts/aquinna.svg?o6zwjj#aquinna') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aquinna' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-360:before {
  content: '\e912';
}
.icon-bars:before {
  content: '\e900';
}
.icon-bed:before {
  content: '\e901';
}
.icon-book:before {
  content: '\e902';
}
.icon-calendar:before {
  content: '\e903';
}
.icon-link:before {
  content: '\e904';
}
.icon-chevron-left:before {
  content: '\e905';
}
.icon-chevron-right:before {
  content: '\e906';
}
.icon-compress:before {
  content: '\e907';
}
.icon-envelope:before {
  content: '\e908';
}
.icon-envelope-square:before {
  content: '\e909';
}
.icon-expand:before {
  content: '\e90a';
}
.icon-fax:before {
  content: '\e90b';
}
.icon-gbp:before {
  content: '\e90c';
}
.icon-s-insta:before {
  content: '\e90d';
}
.icon-s-linked:before {
  content: '\e90e';
}
.icon-map-marker:before {
  content: '\e90f';
}
.icon-phone:before {
  content: '\e910';
}
.icon-spinner:before {
  content: '\e911';
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.icon-pulse {
  -webkit-animation: icon-spin 1s infinite steps(8);
  animation: icon-spin 1s infinite steps(8);
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
