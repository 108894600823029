.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 5px 0;
  margin: 0;
  z-index: 1000;
  width: 100%;
  list-style: none;
  color: #fff;
  background-color: $component-active-bg;
  -webkit-box-shadow: 0 4px 6px 0px rgba(0,0,0,0.4);
  box-shadow: 0 4px 6px 0px rgba(0,0,0,0.4);


  li {
    a {
      display:block;
      color: white;
      padding: 10px;
      text-decoration: none;
    }
    &:hover {
      background: darken($component-active-bg, 10%);
    }
  }
}
